export const enum ImageSources {
  firebase = 1,
}

export enum ImageFolder {
  Vehicle = "vehicles",
  Parts = "parts"
}

export enum SubImageFolder {
  Records = "records"
}