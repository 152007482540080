import { FC, useState } from "react";
import { useFormState } from "../../../hooks/useFormState";
import { TextInput, NumberInput } from "../../shared";
import { SelectOption } from "../../../types/SelectOption";
import { ServiceType, serviceTypes } from "../../../selectOptions/serviceTypes";
import { ImageInput } from "../../shared/ImageInput";
import { DateInput } from "../../shared/DateInput";
import { Validators } from "../../../util/validators";
import { CustomButton } from "../../shared/CustomButton";
import { FuelTopupContainer } from "../fuelTopup/fuelTopup.container";
import { User } from "../../../types/User";
import { Vehicle } from "../../../types/Vehicle";
import { Upload } from "@mui/icons-material";
import { SelectInput } from "../../shared/SelectInput";
import { FormWrapper } from "../../shared/FormWrapper";
import { ImageFolder, SubImageFolder } from "../../../enum/imageCategoryEnums";
import { getFirebaseDownloadURL } from "../../../services/firebase.service";
import { recordVisibility } from "../../../selectOptions/recordVisibility";
import { Grid } from "@mui/material";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { config } from "../../../config";
import { defaultServiceRecord } from "../../../defaultValues/defaultServiceRecord";
import { InputGrid } from "../../shared/InputGrid";
import { appendQueryParam } from "../../../util/helper";
import { MultipleSelectChip } from "../../shared/MultipleSelectChip";

interface Props {
  user: User;
  vehicle: Vehicle;
  loading: boolean;
  initialState?: Partial<ServiceRecord>;
  vehicleListOfUser: SelectOption[];
  onSelectedVehicleChange?: (id: number) => void
  onSave: (r: ServiceRecord, image: Record<number, Blob | null>) => Promise<void>;
}
export const RecordCreate: FC<Props> = ({
  loading,
  user,
  vehicle,
  initialState,
  vehicleListOfUser,
  onSelectedVehicleChange,
  onSave,
}) => {

  const [images, setImages] = useState<Record<number, Blob | null>>({});
  const [ready, setReady] = useState(true);

  const onImageChange = (img: Blob | null, index: number) => {
    setImages(prev => {
      const next = { ...prev };
      next[index] = img;
      return next;
    });
  };

  const {state, changeProperty, changeBulkExtraProperty} = useFormState<ServiceRecord>({
    ...defaultServiceRecord(user),
    ...initialState,
  });

  const handleVehicleSelection = (prop: string, value: string | number) => {
    changeProperty(prop, value);
    onSelectedVehicleChange?.(value as number);
  }

  const getTypeSpecificInputs = (categories: number[]) => {

    if(categories.includes(ServiceType.FuelTopUp)) {
      return <FuelTopupContainer
      vid={state.VehicleId}
      extraProps={state.extraProps}
      loading={loading}
      onExtraChange={changeBulkExtraProperty}
      onReady={setReady}
      ready={ready}
    />
    } else {
      return  <></>
    }
  }

  const imagesObj = JSON.parse(state.images);

  return (
    <>
      <FormWrapper>
        <Grid container justifyContent="flex-end" >
          <Grid item xs={6} sm={4}>
            <SelectInput
              name="visibility"
              label="Visibility"
              variant='outlined'
              size='small'
              options={recordVisibility}
              disabled={loading}
              onChange={changeProperty}
              value={state.visibility}
            />
          </Grid>
        </Grid>

        <br /><br />

        <Grid container>
          <InputGrid minWidth={4}>
            <SelectInput
              value={state.VehicleId}
              name="VehicleId"
              label="Select Vehicle"
              error={!Validators.numberPositive(state.VehicleId)}
              options={vehicleListOfUser}
              disabled={loading}
              onChange={handleVehicleSelection}
            />
          </InputGrid>

          <InputGrid minWidth={4}>
            <DateInput
              value={state.date}
              name="date"
              label="Date"
              disabled={loading}
              error={!Validators.datePast(state.date)}
              onChange={changeProperty}
            />
          </InputGrid>

          <InputGrid minWidth={4}>
            <NumberInput
              value={state.odo}
              name="odo"
              label="Odo meter reading"
              disabled={loading}
              error={!Validators.odo(state.odo)}
              onChange={changeProperty}
            />
          </InputGrid>

          <InputGrid minWidth={12}>
            {/* <SelectInput
              value={recordState.type}
              name="type"
              label="Service Type"
              error={!Validators.recordTypes(recordState.type)}
              options={serviceTypes}
              disabled={loading}
              onChange={changeProperty}
            /> */}
            <MultipleSelectChip
              error={!Validators.recordTypes(state.categories)}
              name="categories"
              value={state.categories}
              label="Service Types"
              options={serviceTypes}
              onChange={changeProperty} />
          </InputGrid>

          {getTypeSpecificInputs(state.categories)}

          <InputGrid minWidth={12}>
            <TextInput
              value={state.desc}
              name="desc"
              label="Description"
              error={!Validators.description(state.desc)}
              disabled={loading}
              multiline
              onChange={changeProperty}
            />
          </InputGrid>

          <InputGrid minWidth={12}>
            {
              Array.from(Array(config.recordImageCount)).map((_, i) => {
                return <Grid padding={1} item xs={6} key={i}><ImageInput

                  index={i}
                  disabled={loading}
                  defaultImageUrl={imagesObj[i] !== undefined && imagesObj[i] !== null ? appendQueryParam(getFirebaseDownloadURL(ImageFolder.Vehicle, vehicle.id, i, SubImageFolder.Records, state.id), 'updatedAt', state.updatedAt.toString()) : config.defaultVehicleImageUrl}
                  onImageSelected={onImageChange}
                /></Grid>;
              })
            }
          </InputGrid>

        </Grid>

        <Grid container justifyContent="end">
          <CustomButton
            EndIcon={Upload}
            label="Save Record"
            disabled={!Validators.validRecord(state)}
            onClick={() => { void onSave(state, images) }}
            loading={loading}
          />
        </Grid>

      </FormWrapper>
    </>
  );
};
