import * as React from 'react';
import { Vehicle } from '../../../types/Vehicle';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { showConfirmBox } from '../../../util/helper';
interface Props {
    v: Vehicle;
    onEdit: (v: Vehicle) => void;
    onChangeVisibility: (v: Vehicle) => void;
    onRequestDelete: (v: Vehicle) => void;
}
export const VehicleActions: React.FC<Props> = ({ v, onEdit, onChangeVisibility, onRequestDelete }) => {

    const handleHide = () => {
        showConfirmBox('We appreciate keeping the data about your vehicle for future owners', 'This vehicle will be removed from your profile. But future owners can search it with the reg.no or chassis no. You can add this to your account again in the future by clicking the ADD TO ACCOUNT button from history view' ).then(() => {
            const newVehicle = { ...v };
            newVehicle.hidden = true;
            onChangeVisibility(newVehicle);
        });
    };

    const handleRestore = () => {
        showConfirmBox('Got this vehicle again?', 'Vehicle will be added to your profile and you can manage the service records again.').then(() => {
            const newVehicle = { ...v };
            newVehicle.hidden = false;
            onChangeVisibility(newVehicle);
        });
    };

    const handleDelete = () => {
        showConfirmBox(`Why don't you click on the HIDE button?`, 'This vehicle and all the service records will be deleted from the entire databse system. If you want to help the community by keeping these valuble information in the databse, please click on the HIDE button. It will removes the vehicle only from your profile. And future owners can refer these service records information.').then(() => {
            onRequestDelete(v);
        });
    };

    return (
        <Grid container justifyContent="end" margin={1}>
            <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                <Button onClick={() => onEdit(v)}>Edit</Button>
                {v.hidden && <Button onClick={() => handleRestore()}>Add to profile</Button>}
                {!v.hidden && <Button onClick={() => handleHide()}>Hide</Button>}
                <Button color='error' onClick={() => handleDelete()}>Delete</Button>
            </ButtonGroup>
        </Grid>
    );
}
