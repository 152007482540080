import Typography from "@mui/material/Typography";
import { FC } from "react";
import { CustomButton } from "../../shared/CustomButton";
import { AutoInput, TextInput } from "../../shared";
import { useFormState } from "../../../hooks/useFormState";
import { Validators } from "../../../util/validators";
import { Send, PublishedWithChanges } from '@mui/icons-material';
import { InputGrid } from "../../shared/InputGrid";
import { FormWrapper } from "../../shared/FormWrapper";
import { Grid } from "@mui/material";
import { PhoneInput } from "../../shared/PhoneInput";
import { ChangePasswordContainer } from "../changePassword/changePassword.container";
import { countryListWithCodes } from "../../../util/countryHelper";

interface Props {
  verified: boolean;
  verificationSent: boolean;
  loading: boolean;
  onSendSMS: (phone: string) => void;
  onSubmitVerifyCode: (identifier: string, code: string) => void;
}
export const ForgotPassword: FC<Props> = ({ verificationSent, loading, verified, onSendSMS, onSubmitVerifyCode }) => {


  const { state, changeProperty } = useFormState({
    identifier: "",
    country: 'LK', // TODO: get default country from cache or browser
    code: ""
  });

  const handleSendResetCode = () => {
    onSendSMS(state.identifier);
  };

  const handleVerificationCode = () => {
    onSubmitVerifyCode(state.identifier.toUpperCase(), state.code)
  }

  // if (verified) {
  //   return <Typography variant="h4">Verified !</Typography>;
  // }

  const handleClick = () => {
    if (verificationSent) {
      handleVerificationCode();
    } else {
      handleSendResetCode();
    }
  }

  return (verified ? <ChangePasswordContainer country={state.country} phone={state.identifier} /> : <FormWrapper>
    <Grid container>
      <InputGrid minWidth={12}>
        <AutoInput
          value={state.country}
          error={!Validators.country(state.country)}
          name="country"
          label="Select Country"
          options={countryListWithCodes()}
          disabled={loading || verificationSent}
          onChange={changeProperty}
        />
      </InputGrid>

      <InputGrid minWidth={12}>
        <PhoneInput
          country={state.country}
          value={state.identifier}
          name="identifier"
          label="Phone Number"
          error={!Validators.validatePhone(state.identifier, state.country)}
          onChange={changeProperty}
          disabled={loading || verificationSent}
          onEnter={handleClick}
        />
      </InputGrid>

      <br />

      {verificationSent && <InputGrid minWidth={12}><TextInput disabled={loading} error={!Validators.code(state.code)} label="Enter Verification Code" value={state.code} name="code" onChange={changeProperty} onEnter={handleClick} /></InputGrid>}
      {verificationSent ? <CustomButton float="center" EndIcon={PublishedWithChanges} disabled={loading || !Validators.code(state.code)} label="Verify" onClick={handleVerificationCode} /> : <CustomButton float="center" EndIcon={Send} disabled={loading || !Validators.validatePhone(state.identifier, state.country)} label="Send Reset Code" onClick={handleSendResetCode} />}

    </Grid>
  </FormWrapper>
  );
};
