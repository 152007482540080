import { FC } from 'react';
import { TextInput } from './TextInput';
import { useAreas } from '../../hooks/useArea';
import { Grid } from '@mui/material';
import { InputGrid } from './InputGrid';
import { AutoInput } from './AutoInput';
import { Vehicle } from '../../types/Vehicle';
import { Part } from '../../types/Part';
import { parseExtraProps, withNotSelectedOption } from '../../util/helper';
interface Props {
    state: Vehicle | Part;
    country: string;
    disabled: boolean;
    changeBulk: (obj: any) => void;
    changeWithExtraProperty: (key: string, value: string | number, fromSuggestions?: boolean) => void;
}
export const LocationInput: FC<Props> = ({ state, changeWithExtraProperty, changeBulk }) => {

    const { getCountry, getProvince, getAreas } = useAreas();
    const onChange = (prop: string, value: string | number) => {
        if(prop === 'province') {
            changeBulk({
                province: value,
                district: -1,
                area: -1
            });
        } else if(prop === 'district') {
            changeBulk({
                district: value,
                area: -1
            });
        }
    }

    return <Grid container>
        <InputGrid minWidth={4}>
            <AutoInput
                name={'province'}
                value={state.province}
                label={'Select Province'}
                disabled={false}
                options={withNotSelectedOption(getCountry(state)?.provinceList ?? [])}
                onChange={onChange}
            />
        </InputGrid>
        <InputGrid minWidth={4}>
            <AutoInput
                name={'district'}
                value={state.district}
                label={'Select District'}
                disabled={false}
                options={withNotSelectedOption(getProvince(state)?.districts ?? [])}
                onChange={onChange}
            />
        </InputGrid>
        <InputGrid minWidth={4}>
            <TextInput
                suggestions={{
                    initDispVal: parseExtraProps(state.extraProps).area ?? '',
                    options: withNotSelectedOption(getAreas(state) ?? [])
                }}
                value={state.area}
                name="area"
                label="City"
                isRequired
                onChange={changeWithExtraProperty}
            />
        </InputGrid>
    </Grid >
}