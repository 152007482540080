import { FC } from "react";
import {
  getFormatteShortdDate
} from "../../../util/helper";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { Part } from "../../../types/Part";
import { partsTypes } from "../../../selectOptions/partsTypes";
import { TableGrid } from "../../shared/TableGrid";
import { config } from "../../../config";
import { LabelHelper } from "../../../util/labelHelper";
import { ImageView } from "../../shared/ImageView";
import { EntityType } from "../../../types/EntityType";
import { useModels } from "../../../hooks/useModels";
import { useBrands } from "../../../hooks/useBrands";
interface Props {
  part: Part;
  onSelect?: (p: Part) => void;
}
export const PartGridTable: FC<Props> = ({ part, onSelect }) => {
  const models = useModels();
  const brands = useBrands();
  
  const categoryLables = LabelHelper.convertPartCategoryLabels(partsTypes, part.categories, []);
  const displayCatLabels = categoryLables.length === 0 ? 'All Vehicle Parts' : categoryLables.length > 5 ? `${categoryLables.splice(0, 3).toString()}...` : categoryLables;

  const anyYear = 'Any Year';
  const minYear = config.yMin === part.yearMin ? anyYear : part.yearMin;
  const maxYear = config.yMax === part.yearMax ? anyYear : part.yearMax;

  const yearRange = minYear === anyYear && maxYear === anyYear ? 'Any Year' : `${minYear}-${maxYear}`;

  return (
    <Grid item xs={12} xl={9}>
      <Grid container sx={{ cursor: "pointer" }} onClick={() => { onSelect?.(part) }} justifyContent="center">
        <TableGrid>
          <ImageView oneImage entity={part} entityType={EntityType.part} />
        </TableGrid>
        <TableGrid>
          {getFormatteShortdDate(part.createdAt)}
        </TableGrid>
        <TableGrid>
          {LabelHelper.getBrandLabelEntity(part, brands)}
        </TableGrid>
        <TableGrid>
          {LabelHelper.getModelLabelEntity(part, models)}
        </TableGrid>
        <TableGrid>
          {part.title.slice(0, 10)}
        </TableGrid>
        <TableGrid minWidth={3}>
          {yearRange}
        </TableGrid>
        <TableGrid minWidth={3}>
          {part.vehicleTypes.map(t => LabelHelper.getVehicleTypeLabel(t)).toString().slice(0, 10)}
        </TableGrid>

        <TableGrid>
          {categoryLables[0]}
        </TableGrid>

        <TableGrid minWidth={3}>
          {part.desc?.slice(0, 10)}
        </TableGrid>

        <TableGrid minWidth={3}>
          {part.price ? `${part.price}/=` : `N/A`}
        </TableGrid>
      </Grid>
      <Divider />
    </Grid>
  );
};
