import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useUser } from "../../../hooks/useUser";
import {
  useDeleteRecordMutation,
  useReadRecordQuery,
} from "../../../state/api/record.api";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { hasUpdateAccess, showErrorToast, showSuccessToast } from "../../../util/helper";
import { RecordActions } from "./recordActions";
import { RecordView } from "./recordView";

export const RecordViewContainer = () => {
  const { rid, vid } = useParams();
  const { user } = useUser();

  useBreadcrumb([{
    label: 'Home',
    path: ``
  },
  {
    label: 'Vehicle',
    path: `/vehicle/${vid}`
  },
  ]);

  const { data: record, isLoading } = useReadRecordQuery({ id: rid ?? "-1" });
  const navigate = useNavigate();
  const [deleteRecord] = useDeleteRecordMutation();

  const handleEdit = (r: ServiceRecord) => {
    navigate(`update`);
  };

  const handleDelete = async (r: ServiceRecord) => {
    if (r.id) {
      await deleteRecord(r);
      navigate(`/vehicle/${vid}`);
    } else {
      showErrorToast("Record id not found");
    }
  };

  return (
    <>
      <RecordView
        user={user}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={isLoading}
        record={record} />
    </>
  );
};
