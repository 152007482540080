import { FC } from "react";
import { Validators } from "../../../util/validators";
import { CustomButton } from "../../shared/CustomButton";
import { AutoInput, TextInput } from "../../shared";
import { useFormState } from "../../../hooks/useFormState";
import { Save, Cancel } from '@mui/icons-material';
import { User } from "../../../types/User";
import { Grid } from "@mui/material";
import { FormWrapper } from "../../shared/FormWrapper";
import { InputGrid } from "../../shared/InputGrid";
import { AuthProvider } from "../../../enum/authProvider.enum";
import { defaultUser } from "../../../defaultValues/defaultUser";
import { PhoneInput } from "../../shared/PhoneInput";
import { countryListWithCodes } from "../../../util/countryHelper";

interface Props {
  initialState: User;
  onlyCountrySelectorPopup: boolean;
  loading: boolean;
  onSave: (user: Omit<User, "id">) => void;
  onClose: () => void;
}
export const UserUpdate: FC<Props> = ({ onlyCountrySelectorPopup, loading, onSave, onClose, initialState }) => {

  const {state, changeProperty, changeBulk} = useFormState<User>({
    ...defaultUser,
    ...initialState,
  });

  const isReadyToSubmit = () => {
    if (
      Validators.text(state.name) &&
      Validators.country(state.country)
    ) {
      return true;
    }
    return false;
  };

  const handleSignUp = () => {
    const obj = { ...state };
    onSave(obj);
  };


  return (
    <FormWrapper>
      <Grid container >

        <InputGrid minWidth={12 }>
          <TextInput
            value={state.name}
            name="name"
            label="Enter Your Name"
            error={!Validators.text(state.name)}
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <AutoInput
            value={state.country}
            name="country"
            error={!Validators.country(state.country)}
            label="Select Country"
            options={countryListWithCodes()}
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
              value={state.desc}
              name="desc"
              label="Address Details"
              onChange={changeProperty}
              disabled={loading}
            />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
              value={state.descPhone}
              name="descPhone"
              label="Contact phone number"
              onChange={changeProperty}
              disabled={loading}
            />
        </InputGrid>

        {!onlyCountrySelectorPopup && <>
          <InputGrid minWidth={6}>
            <TextInput
              value={`99000${state.id}`}
              name="id"
              label="User Id"
              onChange={changeProperty}
              disabled
            />
          </InputGrid>

          <InputGrid minWidth={12}>
            <PhoneInput
              country={state.country}
              value={state.identifier}
              name="identifier"
              label="Identifier"
              error={!Validators.validatePhone(state.identifier, state.country)}
              onChange={changeProperty}
              disabled
            />
          </InputGrid>

          <InputGrid minWidth={6}>
            <TextInput
              value={AuthProvider[state.authProvider]}
              name="authProvider"
              label="Auth Provider"
              onChange={changeProperty}
              disabled
            />
          </InputGrid>

          <InputGrid minWidth={6}>
            <TextInput
              value={state.phone ?? 'N/A'}
              name="phone"
              label="Phone Number"
              onChange={changeProperty}
              disabled
            />
          </InputGrid>

          <InputGrid minWidth={12}>
            <TextInput
              value={state.email ?? 'N/A'}
              name="email"
              label="Email Address"
              onChange={changeProperty}
              disabled
            />
          </InputGrid>
        </>}
        <Grid container justifyContent='center' gap={3}>
          <Grid item sm={4}>
            <CustomButton
              EndIcon={Cancel}
              label="Cancel"
              loading={loading}
              onClick={onClose}
            />
          </Grid>
          <Grid item sm={4}>
            <CustomButton
              EndIcon={Save}
              label="Save "
              loading={loading}
              onClick={handleSignUp}
              disabled={loading || !isReadyToSubmit()}
            />
          </Grid>


        </Grid>
      </Grid>
    </FormWrapper>
  );
};
