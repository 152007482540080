import { FC } from "react";
import { LoginUIMode } from "../../../enum/login.ui.mode";
import { useChangePasswordMutation } from "../../../state/api/user.api";
import { changeLoginMode } from "../../../state/slice/appSlice";
import { useAppDispatch } from "../../../state/store";
import { showErrorToast, showSuccessToast } from "../../../util/helper";
import { ChangePassword } from "./changePassword";

interface Props {
  phone: string;
  country: string;
}
export const ChangePasswordContainer: FC<Props> = ({country, phone}) => {
  const dispatch = useAppDispatch();
  const [changePassword, {isLoading}] = useChangePasswordMutation();

  const handleChangePassword = async (args: {
    identifier: string;
    password: string;
  }) => {
    const result: any =  await changePassword(args);
    if(result.data.status) {
      showSuccessToast('Password changed successfully');
      dispatch(changeLoginMode(LoginUIMode.SIGN_IN));

    } else {
      showErrorToast(result.data.msg);
    }
  };

  return <ChangePassword country={country} identifier={phone} loading={isLoading} onChangePassword={handleChangePassword}/>;
};


