import { UserType } from "../enum/user.type";
import { AuthProvider } from "../enum/authProvider.enum";
import { User } from "../types/User";

export const defaultUser: Omit<User, "id"> = {
  type: UserType.User,
  name: "",
  desc: "",
  phone: '',
  descPhone: '',
  authProvider: AuthProvider.odocert,
  identifier: "",
  country: "LK",
  password: "",
  extraProps: "{}",
  favouriteVehicles: [],
  favouriteParts: [],
  updatedAt: new Date(),
  createdAt: new Date(),
  cacheStamp: ""
}