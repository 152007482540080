import { LocalStorageKeys } from "../enum/localStorageKeys.enum";
import { LoginUIMode } from "../enum/login.ui.mode";
import { PopupType } from "../enum/popup.type";
import { BreadcrumbNode } from "../types/BreadcrumbNode";
import { User } from "../types/User";
import { getLocalStorage } from "../util/localData";

export interface ConfirmBoxResult {
  confirmed: boolean | null;
  data: any;
}
export interface InitialState {
  showSidePanel: boolean;
  networkError: boolean;
  ready: boolean;
  breadcrumbs: BreadcrumbNode[];
  latestVehicleVieWuRL: string;
  login: {
    isVisible: boolean;
    mode: LoginUIMode;
  };
  popup: {
    message: string;
    isOpen: boolean;
    type: PopupType;
  };
  confirmBox: {
    isOpen: boolean;
    header: string;
    text: string;
    inputType?: 'string' | 'number' | 'boolean';
    result: ConfirmBoxResult;
  }
}
export const initialState: InitialState = {
  showSidePanel: false,
  networkError: false,
  ready: false,
  breadcrumbs: [],
  latestVehicleVieWuRL: '',
  login: {
    isVisible: false,
    mode: getLocalStorage(LocalStorageKeys.hasAccount) !== null ?  LoginUIMode.SIGN_IN: LoginUIMode.SIGN_UP,
  },
  popup: {
    message: "",
    isOpen: false,
    type: PopupType.info,
  },
  confirmBox: {
    isOpen: false,
    header: '',
    text: '',
    inputType: undefined,
    result: {
      confirmed: null,
      data: null
    }
  }
};
