import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RootState, useAppDispatch, useAppSelector } from '../../state/store';
import { updateConfirmBox } from '../../state/slice/appSlice';

export const ConfirmBox: React.FC = () => {

    const { isOpen, header, text, result, inputType} = useAppSelector((state: RootState) => state.app.confirmBox);
    const dispatch = useAppDispatch()


    const handleOk = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: true,
                data: null
            }
        }));
    };

    const handleCancel = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: false,
                data: null
            }
        }));
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleCancel}>
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                    {inputType && <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}