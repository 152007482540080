import { CardMedia, Grid, ImageListItem } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { config } from "../../config";
import { ImageFolder, SubImageFolder } from "../../enum/imageCategoryEnums";
import { getFirebaseDownloadURL } from "../../services/firebase.service";
import { appendQueryParam } from "../../util/helper";
import { ServiceRecord } from "../../types/ServiceRecord";
import { Part } from "../../types/Part";
import { Vehicle } from "../../types/Vehicle";
import { EntityType } from "../../types/EntityType";
interface Props {
    entity: Vehicle | Part | ServiceRecord
    entityType: EntityType;
    maxHeight?: boolean;
    oneImage?: boolean;
}
export const ImageView: FC<Props> = ({ oneImage, entity, entityType, maxHeight }) => {

    const [imgUrls, setImageUrls] = useState<string[]>([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        try {
            const urlArr = [];
            const imgObj = JSON.parse(entity.images);

            Object.entries(imgObj).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    const index = Number(key);
                    let imageUrl = '';
                    if (entityType === EntityType.vehicle) {
                        imageUrl = `${appendQueryParam(getFirebaseDownloadURL(ImageFolder.Vehicle, entity.id, index), 'stamp', entity.updatedAt.toString())}`;
                    } else if (entityType === EntityType.record) {
                        imageUrl = `${appendQueryParam(getFirebaseDownloadURL(ImageFolder.Vehicle,
                            // @ts-expect-error
                            entity.VehicleId,
                            index,
                            SubImageFolder.Records,
                            entity.id), 'stamp', entity.updatedAt.toString())}`;
                    } else if (entityType === EntityType.part) {
                        imageUrl = `${appendQueryParam(getFirebaseDownloadURL(ImageFolder.Parts, entity.id, index), 'stamp', entity.updatedAt.toString())}`;
                    }
                    urlArr.push(imageUrl);
                }
            });

            if (urlArr.length === 0) {
                urlArr.push(config.defaultVehicleImageUrl);
            }

            setImageUrls(urlArr);
        } catch (e) {
            console.log(e);
        }
    }, [entity]);

    const handleVisibilityChange = (visible: boolean) => {
        setOpen(visible);
    }

    const drawImages = isOpen ? imgUrls : [imgUrls[0]];

    if (oneImage) {
        return <ImageListItem> <img style={{ 'height': '35px' }} src={drawImages[0]} /></ImageListItem>
    }

    return (
        <Grid margin="auto">
            <PhotoProvider onVisibleChange={handleVisibilityChange}>
                {drawImages.map((url, index) =>
                    <PhotoView src={url} key={url ?? index} >
                        <CardMedia
                            component="img"
                            height={maxHeight ? config.vehicleViewHeight : "150"}
                            image={url}
                            alt="Image N/A"
                        />
                    </PhotoView>
                )}
            </PhotoProvider>
        </Grid>
    )
};