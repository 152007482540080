import { Card, CardActionArea, CardMedia, CardContent, Typography, Grid, Box } from "@mui/material"
import buysell from '../../assets/buysell.png';
import service from '../../assets/service.png';
import searchplate from '../../assets/searchplate.png';
import { Link } from "react-router-dom";
import { User } from "../../types/User";
import { FC } from "react";

interface Props {
    user: User | null | undefined;
    handleToggleSidePanel: (visiblity: boolean) => void;
}
export const Home: FC<Props> = ({ user, handleToggleSidePanel }) => {
    return <>
        <Grid container sx={{ height: 'calc(100vh - 210px)' }} alignItems="center" justifyContent="center" marginTop={1}>
            <Link to={"/vehicle/market"} style={{ textDecoration: 'none' }}>
                <Card sx={{ maxWidth: '340px', margin: '10px' }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="100"
                            image={buysell}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Market
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Buy, sell vehicles or parts. Use the comprehensive filters to find the best matching vehicles or parts. You can save the FILTERS or favorite VEHICLES, PARTS for future use
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>

            <Link to={`/vehicle/myOwn`} style={{ textDecoration: 'none' }}>
                <Card sx={{ maxWidth: '340px', margin: '10px' }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="100"
                            image={service}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Maintanance Records
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add vehicle maintanance records(private or public) of your vehicles at OdoCert and buyers can refer these history records when they buy a new vehicle.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>

            <Link to={`/vehicle/search?key=`} style={{ textDecoration: 'none' }}>
                <Card sx={{ maxWidth: '340px', margin: '10px' }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="100"
                            image={searchplate}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Search Vehicle History
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Search any vehicle which is maintained with the OdoCert by its number plate or chassis number and observe this history records to find out fake millage settings.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </Grid>
    </>
}