import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { withScroller } from "../../../hoc/withScroller";
import { FC } from "react";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { getServiceTypes, parseExtraProps } from "../../../util/helper";
import { TimelineAdd } from "../../shared/addNew/timeLineAdd";
import { ServiceType } from "../../../selectOptions/serviceTypes";
import { LabelHelper } from "../../../util/labelHelper";
import { DynamicFeed } from "@mui/icons-material";
import { Vehicle } from "../../../types/Vehicle";
import { FuelType } from "../../../enum/fuelType.enum";

export interface Props {
  hasMore: boolean;
  loading: boolean;
  items: ServiceRecord[];
  isOwner: boolean;
  vehicle: Vehicle;
  onAdd: () => void;
  onSelect: (Record: ServiceRecord) => void;
  onLoadMore: (nextLimit: number) => void;
}

const RecordsTimelineViewComp: FC<Props> = ({
  isOwner,
  items,
  vehicle,
  onSelect,
  onAdd
}) => {
  if (items) {
    return (
      <Timeline position="alternate">
        {isOwner && <TimelineAdd onAdd={onAdd} />}
        {items.map((r) => {
          const fuelUnit = vehicle.fuel === FuelType.Electric ? 'kwh' : 'l';
          return (
          <TimelineItem onClick={() => onSelect(r)} key={`${r.id}`} sx={{ cursor: 'pointer' }}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <Typography variant="body2">
                {new Date(r.date).toLocaleDateString()}
              </Typography>
              <Typography variant="subtitle2">
                {r.categories?.includes(ServiceType.FuelTopUp) ? `Volume: ${parseExtraProps(r.extraProps).volume}${fuelUnit}, Cost: ${parseExtraProps(r.extraProps).cost}/=` : r.desc}
              </Typography>

            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              {
                r.categories?.length === 1 ? <TimelineDot color={getServiceTypes(r.categories)[0].color}>
                  {getServiceTypes(r.categories)[0]?.Icon}
                </TimelineDot> : <TimelineDot color={"warning"}>
                  <DynamicFeed />
                </TimelineDot>

              }

              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 2 }}>
              <Typography variant="h6" component="span">
                {LabelHelper.getServiceTypesLabel(r.categories, true)}
              </Typography>
              <Typography variant="subtitle1">{r.odo} Km</Typography>
            </TimelineContent>
          </TimelineItem>
        )})}
      </Timeline>
    );
  }
  console.log('Not found');
  return <div>Record Not found</div>;
};

export const RecordsTimelineView = withScroller(RecordsTimelineViewComp);
