import { FC, useState } from "react";
import { useSendResetMutation, useVerifyResetMutation } from "../../../state/api/user.api";
import { isSuccessResult } from "../../../util/helper";
import { ForgotPassword } from "./forgotPassword";

export const ForgotPasswordContainer: FC = () => {

  const [sendResetCode] = useSendResetMutation();
  const [sendVerifyCode] = useVerifyResetMutation();

  const [verificationSent, setVerificationSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [busy, setBusy] = useState(false);

  const handleSubmitVerificationCode = async (identifier: string, code: string) => {
    setBusy(true);
    const result = await sendVerifyCode({ identifier, code });
    if (isSuccessResult(result)) {
      setVerified(true);
    }
    setBusy(false);
  }

  const handleSendSMS = async (phoneNum: string) => {
    setBusy(true);
    const result = await sendResetCode(phoneNum);
    setVerificationSent(true);
    if (isSuccessResult(result)) {
      setVerificationSent(true);
    } else {
      setVerificationSent(false);
    }
    setBusy(false);
  }

  return <ForgotPassword
    verified={verified}
    loading={busy}
    verificationSent={verificationSent}
    onSendSMS={handleSendSMS}
    onSubmitVerifyCode={handleSubmitVerificationCode}
  />;
};
