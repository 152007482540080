import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { config, firebaseConfigDev, firebaseStr } from "../config";
import { ImageFolder, SubImageFolder } from "../enum/imageCategoryEnums";
import { getStringFromHash, isDev } from "../util/helper";

const firebaseConfig = JSON.parse(getStringFromHash(firebaseStr));
export const fbApp = initializeApp(isDev() ? firebaseConfigDev : firebaseConfig);

const storage = getStorage();
const metadata = {
  contentType: "image/jpeg",
  cacheControl: `private, max-age=${config.imageCacheSeconds}`,
};

export const uploadFileToFirebase = async (file: File, name: string) => {
  try {
    const storageRef = ref(storage, name);
    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file, metadata);
    // console.log('uploaded', result);
  } catch (e) {
    console.error("upload failed", e);
  }
};

export const deleteFileFromFirebase = async (folder: ImageFolder, vehicleIdOrPartId: number, idx: number, subImageFolder?: SubImageFolder) => {
  try {
    // Create a reference to the file to delete
    const desertRef = ref(storage, `${folder}/${vehicleIdOrPartId}/${idx}.jpg`);

    // Delete the file
    await deleteObject(desertRef);
  } catch (e) {
    console.error("storage deletion failed", e);
  }
};

export const deleteFilesInDir = async (folder: ImageFolder, id: number) => {
  try {
    // Create a reference to the file to delete
    const listRef = ref(storage, `${folder}/${id}`);
    const res = await listAll(listRef)

    res.items.forEach(async (itemRef) => {
      console.log('deleting', itemRef.fullPath);
      await deleteObject(itemRef);
    });
  } catch (e) {
    console.error("storage deletion failed", e);
  }
};

/**
 * 
 * @param imageFolder 
 * @param resourceId vehicleId for vehicles/records, partId for parts 
 * @param id vehicleId for vehicles, recordId for records, partId for parts
 * @param imgs 
 */
export const syncImages = async (
  imageFolder: ImageFolder,
  resourceId: number,
  imgs: Record<number, Blob | null>,
  subImageFolder?: SubImageFolder,
  subResourceId?: number
) => {
  for (const [idx, uploadImage] of Object.entries(imgs)) {
    if (uploadImage) {
      const imagePath = `${imageFolder}/${resourceId}/${subResourceId ? `${subImageFolder}/${subResourceId}/`: ``}${idx}.jpg`;
      const file = dataURLtoFile(uploadImage, imagePath);
      await uploadFileToFirebase(file, imagePath);
    } else {
      await deleteFileFromFirebase(imageFolder, resourceId, Number(idx));
    }
  };
}

export const getFirebaseDownloadURL = (
  imageFolder: ImageFolder,
  resourceId: number,
  idx: number,
  subImageFolder?: SubImageFolder,
  subResourceId?: number
) => {
  const imagePath = `${imageFolder}%2F${resourceId}%2F${subResourceId ? `${subImageFolder}%2F${subResourceId}%2F`: ``}${idx}.jpg?alt=media`;
  return `https://firebasestorage.googleapis.com/v0/b/${config.firebaseStorageUrl}/o/${imagePath}`;
};

export const dataURLtoFile = (dataurl: any, filename: string): File => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n-- !== 0) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], filename, { type: mime });
  return file;
};