import { FC } from "react";
import { Validators } from "../../../util/validators";
import { AutoInput, TextInput } from "../../shared";
import { useFormState } from "../../../hooks/useFormState";
import { CustomButton } from "../../shared/CustomButton";
import { Login } from '@mui/icons-material';
import { getLocalStorage } from "../../../util/localData";
import { InputGrid } from "../../shared/InputGrid";
import { FormWrapper } from "../../shared/FormWrapper";
import { Grid } from "@mui/material";
import { PhoneInput } from "../../shared/PhoneInput";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { countryListWithCodes } from "../../../util/countryHelper";

interface Props {
  loading: boolean;
  onSignIn: (args: { country: string; identifier: string; password: string }) => void;
}
export const SignIn: FC<Props> = ({ loading, onSignIn }) => {

  const {state, changeProperty, changeBulk} = useFormState({
    identifier: '',
    password: "",
    country: getLocalStorage(LocalStorageKeys.country) ?? "LK"
  });

  const handleSignIn = () => {
    const obj = { ...state };
    onSignIn(obj);
  };

  const handleCountryChange = (key: string, value: string | number) => {
    changeBulk({
      [key]: value,
      identifier: ''
    })
  };

  return (
    <FormWrapper>
      <Grid container >
        <InputGrid minWidth={12}>
          <AutoInput
            value={state.country}
            name="country"
            label="Select Country"
            error={!Validators.country(state.country)}
            options={countryListWithCodes()}
            disabled={loading}
            onChange={handleCountryChange}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <PhoneInput
            country={state.country}
            value={state.identifier}
            name="identifier"
            label="Phone Number"
            error={!Validators.validatePhone(state.identifier, state.country)}
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
            value={state.password}
            type="password"
            name="password"
            label="Password"
            error={!Validators.password(state.password)}
            onChange={changeProperty}
            disabled={loading}
            onEnter={handleSignIn}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <CustomButton
            float="center"
            EndIcon={Login}
            label="Sign In"
            loading={loading}
            onClick={handleSignIn}
            disabled={loading || !Validators.validLoginUser(state)}
          />
        </InputGrid>

      </Grid>
    </FormWrapper>
  );
};
