import { FC, useState } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { Grid } from "@mui/material";
import { Speed, LocalGasStation, Category, PermDataSetting, SettingsApplications, Event, FmdGood } from '@mui/icons-material';
import { getFormatteShortdDate } from "../../../util/helper";
import { Detail } from "../../shared/Detail";
import { FuelType } from "../../../enum/fuelType.enum";
import { LabelHelper } from "../../../util/labelHelper";
import { DescriptionView } from "../../shared/DescriptionView";
import { LocationView } from "../../shared/LocationView";

interface Props {
  state: Vehicle;
  onRequestFuelStat: () => Promise<string>;
}
export const VehicleMoreDetail: FC<Props> = ({ state, onRequestFuelStat }) => {

  const [fuelStat, setFuelStat] = useState('calculate?');
  const powerLabel = state.fuel === FuelType.Electric ? 'Motor(kw)' : 'Engine CC';

  const handleFuelStatRequest = async () => {
    setFuelStat('loading...');
    const fuelStat = await onRequestFuelStat();
    setFuelStat(fuelStat);
  }

  return (
    <Grid container>
      {/* <Grid item xs={6} sm={6} ><Detail name="Location" value={'N/A2 Location'} icon={<FmdGood color="secondary" />} /></Grid> */}
      <Grid item xs={6} sm={6} ><Detail name="Category" value={LabelHelper.getVehicleTypeLabel(state.type)} icon={<Category color="success" />} /></Grid>
      <Grid item xs={6} sm={6} ><Detail name="Fuel Type" value={LabelHelper.getFuelTypeLabel(state.fuel)} icon={<LocalGasStation color="info" />} /></Grid>
      <Grid item xs={6} sm={6} ><Detail name={powerLabel} value={state.cc} icon={<PermDataSetting color="warning" />} /></Grid>
      <Grid item xs={6} sm={6} ><Detail name="Transmission" value={LabelHelper.getTransmissionTypeLabel(state.gear)} icon={<SettingsApplications color="error" />} /></Grid>

      <Grid item xs={6} sm={6} ><Detail name="Odo Meter" value={`${state.odo} km`} icon={<Speed color="primary" />} /></Grid>
   
      <Grid item xs={6} sm={6} ><Detail name="Created On" value={getFormatteShortdDate(state.date)} icon={<Event color="secondary" />} /></Grid>
      
      <Grid item xs={6} sm={6} sx={{cursor: 'pointer'}}>
        <div onClick={handleFuelStatRequest} >
        {/* <Tooltip onClick={handleFuelStatRequest} title={`Recent ${config.fuelRecordsPageSize} fuel records are considered to calculate fuel consumption`}> */}
          <Detail name="Fuel Economy" value={fuelStat} icon={<LocalGasStation color="success" />} />
        {/* </Tooltip> */}
        </div>
       
      </Grid>
      <LocationView state={state} />
       <DescriptionView desc={state.desc} />
    </Grid>
  );
};
