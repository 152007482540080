import * as React from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { showConfirmBox } from '../../../util/helper';
import { Part } from '../../../types/Part';

interface Props {
    p: Part;
    onEdit: (p: Part) => void;
    onDelete: (p: Part) => void;
}
export const PartActions: React.FC<Props> = ({ p, onEdit, onDelete }) => {

    const handleDelete = () => {
        showConfirmBox(`Why don't you click on the HIDE button?`, 'This vehicle and all the service records will be deleted from the entire databse system. If you want to help the community by keeping these valuble information in the databse, please click on the HIDE button. It will removes the vehicle only from your profile. And future owners can refer these service records information.').then(() => {
            onDelete(p);
        });
    };

    return (
        <Grid container justifyContent="end" margin={1}>
             <Grid item >  
                <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                    <Button onClick={() => onEdit(p)}>Edit</Button>
                    <Button color="error" onClick={handleDelete}>Delete</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}
