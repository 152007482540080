import { Button } from "@mui/material";
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider, getRedirectResult } from "firebase/auth";
import { Google } from '@mui/icons-material';
import { FC, useEffect } from "react";
import { SocialUser } from "../../../../types/SocialUser";
import { AuthProvider } from "../../../../enum/authProvider.enum";
import { config } from "../../../../config";

export const GoogleLogin: FC<{
    onLogin: (token: string, user: SocialUser) => void;
}> = ({ onLogin }) => {

    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    useEffect(() => {
        auth.onAuthStateChanged(handleLoginSuccess);
    }, []);

    const handleLoginSuccess = async (result: any) => {
        if (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);

            if (result?.user) {
                // console.log(result);
                const token = await result.user.getIdToken();
                // The signed-in user info.
                // const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...

                const userInfo: SocialUser = {
                    identifier: result.user.uid,
                    name: result.user.displayName,
                    phone: result.user.phoneNumber,
                    photo: result.user.photoURL,
                    email: result.user.email,
                    authProvider: AuthProvider.google
                }

                if (token) {
                    console.log('Signed in with Google');
                    onLogin(token, userInfo);
                }
            }
        }
    }

    const handleLogin = () => {
        if (config.signInWithRedirect) {
            signInWithRedirect(auth, provider);
        } else {
            signInWithPopup(auth, provider)
                .then(handleLoginSuccess).catch((error) => {
                    console.error(error);
                });
        }
    }

    return <Button color="error" fullWidth variant="contained" onClick={handleLogin} startIcon={<Google />}>
        Login with Google
    </Button>
}

