import { ToggleButtonGroup, ToggleButton, Grid, Tooltip } from "@mui/material";
import { Timeline, ViewQuilt, InsertChart, Group } from "@mui/icons-material";
import { FC, useState } from "react";
import { RecordFilter } from "../filter/recordFilter";
import { RecordsFilterType } from "../../../types/filters/RecordsFilterType";
import { Vehicle } from "../../../types/Vehicle";

interface Props {
  vehicle: Vehicle;
  showChart: boolean;
  viewMode: "Timeline" | "Grid";
  filter: RecordsFilterType;
  onFilterChange: (filter: RecordsFilterType) => void;
  onViewModeChange: (mode: "Timeline" | "Grid") => void;
  // onSearchModeChange: (searchMode: "Limited" | "Full") => void;
  onChartVisibilityChange: (showChart: boolean) => void;
}

export const RecordViewToggle: FC<Props> = ({ onViewModeChange, onFilterChange, onChartVisibilityChange, showChart, viewMode, vehicle, filter }) => {

  const [recordDepth, setRecordDepth] = useState<'Full' | 'Limited'>(filter.VehicleId === undefined ? 'Full' : 'Limited');

  const handleDepthChange = () => {
    const next = recordDepth === 'Full' ? 'Limited' : 'Full';
    setRecordDepth(next);

    const newFilter = {...filter};
    if(next === 'Full') {
      newFilter.VehicleId = undefined;
    } else {
      newFilter.VehicleId = vehicle.id;
    }
    onFilterChange(newFilter);
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <ToggleButtonGroup
          color="primary"
          value={viewMode}
          exclusive
          aria-label="Platform"
          sx={{ marginTop: 2 }}
        >
          <ToggleButton
            className="timeline-icon"
            value="Timeline"
            onClick={() => onViewModeChange("Timeline")}
          >
            <Timeline />
          </ToggleButton>
          <ToggleButton className="grid-icon" value="Grid" onClick={() => onViewModeChange("Grid")}>
            <ViewQuilt />
          </ToggleButton>
        </ToggleButtonGroup>


        <Grid item>
          <ToggleButtonGroup
            color="primary"
            value={showChart}
            exclusive
            aria-label="Platform"
            sx={{ marginTop: 2 }}
          >
            <ToggleButton className="chart-icon" value="show" onClick={() => onChartVisibilityChange(true)}>
              <Tooltip title="Show millage chart">
                <InsertChart />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={recordDepth}
            exclusive
            aria-label="Platform"
            sx={{ marginTop: 2 }}
          >

            <ToggleButton className="merge-icon" value="Full" onClick={handleDepthChange}>
              <Tooltip title="Show records from all owners">
                <Group />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={recordDepth}
            exclusive
            aria-label="Platform"
            sx={{ marginTop: 2 }}
          >
            <RecordFilter filter={filter} onFilterChange={onFilterChange} />
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};
