import { Navigate, Route, Routes } from "react-router-dom";
import { RecordViewContainer } from "./components/record/view/recordView.container";
import { RecordUpdateContainer } from "./components/record/update/recordUpdate.container";
import { VehicleViewContainer } from "./components/vehicle/view/vehicleView.container";
import { VehicleSearchRoute } from "./components/vehicle/search/vehicleSearch.route";
import { AdminContainer } from "./components/admin/admin.container";
import { useAppSelector } from "./state/store";
import { isAdmin } from "./util/helper";
import { VehicleUpdateContainer } from "./components/vehicle/update/vehicleUpdate.container";
import { UserUpdateContainer } from "./components/user/update/userUpdate.container";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoginContainer } from "./components/user/login.container";
import { RecordCreateRoute } from "./components/record/create/recordCreate.route";
import { FavouritesContainer } from "./components/favourites/favorites.container";
import { PartCreateContainer } from "./components/parts/create/partCreate.container";
import { PartViewContainer } from "./components/parts/view/partView.container";
import { PartUpdateContainer } from "./components/parts/update/partUpdate.container";
import { HomeContainer } from "./components/intro/home.container";
import { PartsListRoute } from "./components/parts/list/partsList.route";
import { VehicleListRoute } from "./components/vehicle/list/vehicleList.route";
import { VehicleCreateRoute } from "./components/vehicle/create/vehicleCreate.route";
import { ListingType } from "./enum/vehicleListing.enum";
import { ItemViewMode } from "./enum/itemViewMode.enum";
import { LocalStorageKeys } from "./enum/localStorageKeys.enum";
import { useUser } from "./hooks/useUser";
import { OwnerTypes } from "./enum/ownerTypes.enum";

// TODO: all update paths needs to be secured with ownership of that resource
export const Router = () => {
  const { ready } = useAppSelector((state) => state.app);
  const { user } = useUser();

  if (!ready) {
    return <></>;
  }

  return (
    <Routes>
      <Route path={""} element={<HomeContainer />} />

      <Route path={"/user"}>
        <Route path={"edit"} element={<ProtectedRoute><UserUpdateContainer /></ProtectedRoute>} />
      </Route>

      <Route path={"/login"} element={user ? <Navigate to="/" replace /> : <LoginContainer />} />
      <Route path={"favourites"} element={<ProtectedRoute><FavouritesContainer /></ProtectedRoute>} />

      <Route path={"sparepart"}>
        <Route path={":pid/update"} element={<ProtectedRoute><PartUpdateContainer /></ProtectedRoute>} />
        <Route path={"mySales"} element={<ProtectedRoute><PartsListRoute partsFilterKey={LocalStorageKeys.mySalesPartsFilter} viewMode={ItemViewMode.table} title="Your Parts Listings" subTitle="Listings Added By You For Sale" ownerType = {OwnerTypes.My} /></ProtectedRoute>} />
        <Route path={"shop/:sellerId"} element={<PartsListRoute partsFilterKey={LocalStorageKeys.shopPartsFilter} viewMode={ItemViewMode.grid}  title="" subTitle="" ownerType = {OwnerTypes.Given} />} />
        <Route path={"market"} element={<PartsListRoute partsFilterKey={LocalStorageKeys.marketPartsFilter} viewMode={ItemViewMode.grid}  title="Parts For Sale" subTitle="Search The Parts For Your Vehicle All Around The Country" ownerType = {OwnerTypes.Any} />} />
        <Route path={"create"} element={<ProtectedRoute><PartCreateContainer /></ProtectedRoute>} />
        <Route path={":pid"} element={<PartViewContainer />} />
      </Route>

      <Route path={"vehicle"}>
        <Route path={":vid/record/:rid/update"} element={<ProtectedRoute><RecordUpdateContainer /></ProtectedRoute>} />
        <Route path={":vid/record/:rid"} element={<RecordViewContainer />} />
        <Route path={":vid/record/create"} element={<ProtectedRoute><RecordCreateRoute /></ProtectedRoute>} />
        <Route path={":vid/update"} element={<ProtectedRoute><VehicleUpdateContainer title="Edit Vehicle" subTitle="Change the existing settings of the vehicle" /></ProtectedRoute>} />
        <Route path={"mySales"} element={<ProtectedRoute><VehicleListRoute filterStorageKey={LocalStorageKeys.mySalesVehiclesFilter} viewMode={ItemViewMode.table} title="Your Vehicles Listings" subTitle="Show your vehicle advertisement to thousands of buyers" buttonSubText="For Your Market" listedFor={ListingType.ListingForSale} ownerType = {OwnerTypes.My}/></ProtectedRoute>} />
        <Route path={"search"} element={<VehicleSearchRoute />} />
        <Route path={"shop/:sellerId"} element={<VehicleListRoute filterStorageKey={LocalStorageKeys.shopVehiclesFilter} viewMode={ItemViewMode.grid} title="" subTitle="SHOP PARTS HERE " buttonSubText="For Your Market" listedFor={ListingType.ListingForSale} ownerType = {OwnerTypes.Given}/>} />
        <Route path={"market"} element={<VehicleListRoute filterStorageKey={LocalStorageKeys.marketVehiclesFilter} viewMode={ItemViewMode.grid} title="Vehicle Sales" subTitle="Search The Vehicles All Around The Country" buttonSubText="For Sale"  listedFor={ListingType.ListingForSale} ownerType = {OwnerTypes.Any} />} />
        <Route path={"create"} element={<ProtectedRoute><VehicleCreateRoute title="Add Vehicle" subTitle="Add new vehicle for" /></ProtectedRoute>} />
        <Route path={"myOwn"} element={<ProtectedRoute><VehicleListRoute filterStorageKey={LocalStorageKeys.myAccountVehiclesFilter} viewMode={ItemViewMode.table} title="Your Owned Vehicles" subTitle="Maintain The Service Records For Your Vehicle" buttonSubText="For Your Profile" listedFor={ListingType.ListingForMyOwn} ownerType = {OwnerTypes.My} hideFilter /></ProtectedRoute>} />
        <Route path={":vid"} element={<VehicleViewContainer />} />
      </Route>
 
      {isAdmin(user) && <Route path={"admin"}>
        <Route path={""} element={<AdminContainer />} />
      </Route>}
    </Routes>
  )
};
