import { Grid } from "@mui/material";
import { useSearchPartsQuery } from "../../../state/api/parts.api";
import { PartsList } from "./partsList";
import { FC, useState } from "react";
import { Part } from "../../../types/Part";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../shared/PageHeader";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { SearchParams } from "../../../enum/searchParams.enum";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { SearchBox } from "../../shared/SearchBox";
import { PartsFilter } from "../filter/PartsFilter";
import { PartsFilterType } from "../../../types/filters/PartsFilterType";
import { getLocalStorage, setLocalStorage } from "../../../util/localData";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { useUserForEntityFetch } from "../../../hooks/useUserForEntityFetch";
import { useGetUserInfoQuery } from "../../../state/api/user.api";
import { PhoneDial } from "../../shared/PhoneDial";

export const PartsListContainer: FC<{
  ownerType: OwnerTypes;
  title: string;
  subTitle: string;
  viewMode: ItemViewMode;
  partsFilterKey: LocalStorageKeys;
}> = ({ ownerType, title, subTitle, viewMode, partsFilterKey }) => {

  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);

  const emptyFilter = `{}`;
  const savedFilter = getLocalStorage(partsFilterKey, emptyFilter);

  const UserId = useUserForEntityFetch(ownerType);
  
  const shopOwner = useGetUserInfoQuery({id: ownerType === OwnerTypes.Given ? Number(UserId) : -1})
  
  let dispTitle = title;
  let dispSubtitle = subTitle;
  if (ownerType === OwnerTypes.Given && shopOwner) {
      dispTitle = shopOwner?.data?.name;
      dispSubtitle = shopOwner?.data?.desc
  }

  const initialFilterWithUserIdAndListingType = {
    UserId
  }

  // keywords and other filters are separated since keyword is read from a different textbox other than fiter dialog
  // these params should be included at market.route page to reset the whole component when these params are changed
  const { searchParamValue: partsFilterObjFromUrl, changeSearchParam: setPartsFilterObj } = useSearchParamsState<string>(SearchParams.partsFilterObj, JSON.stringify(initialFilterWithUserIdAndListingType));
  const { searchParamValue: partsFilterKeywords, changeSearchParam: setPartsFilterKeywords } = useSearchParamsState<string>(SearchParams.partsFilterKeywords, '');
  const { searchParamValue: partsFilterEnabled, changeSearchParam: setPartsFilterEnabled } = useSearchParamsState<string>(SearchParams.partsFilterEnabled, 'true');


  const partsFilterObj = JSON.parse(partsFilterObjFromUrl === `{}` ? savedFilter : partsFilterObjFromUrl);

  // Pagination starts
  const [query, setQuery] = useState({
    // filter: partsFilter,
    keywords: partsFilterKeywords,
    filter: partsFilterEnabled === 'true' ?  partsFilterObj : initialFilterWithUserIdAndListingType,
    limit: config.pageSize,
    offset: 0,
    ownerType
  });
  const { data, isFetching } = useSearchPartsQuery(query);
  const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
  // Pagination ends

  const handleFilterChange = async (filter: PartsFilterType) => {
    const filterObj = JSON.stringify(filter);
    setPartsFilterObj(filterObj);
    setLocalStorage(partsFilterKey, filterObj);
  }

  const handleFilterEnable = (enabled: boolean) => {
    setPartsFilterEnabled(enabled ? 'true' : 'false');
  }

  const handleSearchChange = (keyword: string) => {
    const lowercaseKw = keyword.toLowerCase();
    setPartsFilterKeywords(lowercaseKw);
  }

  const handleSelect = (p: Part) => {
    const path = `/sparepart/${p.id}`;
    navigate(path);
  };

  const handleAdd = () => {
    const path = `/sparepart/create`;
    navigate(path);
  };

  return <>
    <Grid container justifyContent="end">
           { shopOwner?.data && <Grid item><PhoneDial phone={shopOwner?.data?.descPhone} /></Grid>}
    </Grid>
    <PageHeader header={dispTitle} subHeader={dispSubtitle} />

    <Grid container justifyContent='end' alignItems='center'>
      <Grid item>
        <PartsFilter enabled={partsFilterEnabled === 'true'} initialState={partsFilterObj} loading={false} open={openFilter} onFilterChange={handleFilterChange} onClose={() => setOpenFilter(false)} />
      </Grid>
      <Grid item>
        <SearchBox placeHolder="Search Spare Parts" filterEnabled={partsFilterEnabled === 'true'} onFilterEnable={handleFilterEnable} onChange={handleSearchChange}  value={partsFilterKeywords} onFilterOpen={() => setOpenFilter(true)} />
      </Grid>
    </Grid>

    <PartsList
      viewMode={viewMode}
      onAdd={handleAdd}
      hasMore={data?.length > 0}
      loading={isFetching}
      items={mergedData}
      onLoadMore={handleLoadMore}
      onSelect={handleSelect} />
  </>
}