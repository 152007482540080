import { FC } from "react";
import { Vehicle } from "../../../types/Vehicle";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { FuelType } from "../../../enum/fuelType.enum";
import { TableGrid } from "../../shared/TableGrid";
import { LabelHelper } from "../../../util/labelHelper";
import { ImageView } from "../../shared/ImageView";
import { getFormatteShortdDate, isMobile } from "../../../util/helper";
import { EntityType } from "../../../types/EntityType";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
interface Props {
  vehicle: Vehicle;
  onSelect?: (v: Vehicle) => void;
  onUpdate?: (v: Vehicle) => void;
  onDelete?: (v: Vehicle) => void;
}
export const VehicleGridTable: FC<Props> = ({ vehicle, onSelect }) => {

  const fuelLabel = vehicle.fuel === FuelType.Electric ? 'KWH Price' : 'Fuel Price';
  const desktop = !isMobile();
  const brands = useBrands();
  const models = useModels();

  return (
    <Grid item xs={12} xl={9}>
      <Grid container sx={{ cursor: "pointer" }} onClick={() => { onSelect?.(vehicle) }} justifyContent="center">
        <TableGrid>
          <ImageView entity={vehicle} oneImage entityType={EntityType.vehicle} />
        </TableGrid>
        <TableGrid>
          {vehicle.listing === ListingType.ListingForSale ? getFormatteShortdDate(vehicle.createdAt): LabelHelper.displayRegNo(vehicle.regNo)}
        </TableGrid>
        <TableGrid>
          {LabelHelper.getBrandLabelEntity(vehicle, brands)}
        </TableGrid>
        <TableGrid>
          {LabelHelper.getVehicleTypeLabel(vehicle.type)}
        </TableGrid>
        <TableGrid minWidth={3}>
            {vehicle.listing === ListingType.ListingForSale ? `${vehicle.price}/=` : LabelHelper.getTransmissionTypeLabel(vehicle.gear)}
          </TableGrid>
        {desktop && <>
          <TableGrid>
            {vehicle.year}
          </TableGrid>
          <TableGrid minWidth={3}>
            {LabelHelper.getModelLabelEntity(vehicle, models)}
          </TableGrid>
          <TableGrid>
            {vehicle.cc} CC
          </TableGrid>

          <TableGrid>
            {LabelHelper.getFuelTypeLabel(vehicle.fuel)}
          </TableGrid>

          <TableGrid minWidth={3}>
            {vehicle.odo}km
          </TableGrid>
        </>}
      </Grid>
      <Divider />
    </Grid>
  );
};
